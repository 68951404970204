import React from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

const containerStyles = {
  /* Common styles for all screen sizes */
  color: '#FFF',
  fontSize: '35px',
  fontWeight: 'bold',
  lineHeight: '60px',
  marginRight: '80px',
  paddingLeft: '',
  width: '60px',
  height: '60px',
  borderRadius: '100px',
  background: '#263489',
  boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.2)',
};

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  const screenWidth = window.innerWidth;

  const customStyles = {
    ...containerStyles,
    
    right: screenWidth <= 768 ? '-5.5rem' : '-6rem', // Apply styles based on screen width
    top: screenWidth <= 768 ? '13.5rem' : '13.5rem',
  };

  return (
    <div className={className} style={customStyles} onClick={onClick}>
      <span style={{ marginLeft: '8px' }}><RightOutlined /></span>
    </div>
  );
};

export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  const screenWidth = window.innerWidth;

  const customStyles = {
    ...containerStyles,
    zIndex: 9,
    left: screenWidth <= 768 ? '-7px' : '-6px', // Apply styles based on screen width
    top: screenWidth <= 768 ? '13.5rem' : '13.5rem',
  };

  return (
    <div className={className} style={customStyles} onClick={onClick}>
      <span style={{ marginLeft: '8px' }}><LeftOutlined /></span>
    </div>
  );
};
