import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { Link } from "react-router-dom";
import classes from "./Profile.module.css"
import { Button, Col, Row, Collapse, Select, Checkbox, Form, Input, Table } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import announcementImg from '../../img/Announcement1.png'
import { useEffect, useState } from "react";
import teams from '../../img/Microsoft_Office_Teams.png'
import powerPoint from '../../img/Microsoft_Office_PowerPoint.png'
import sway from '../../img/Microsoft_Office_Sway.png'
import oneNote from '../../img/Microsoft_Office_OneNote.png'
import visio from '../../img/Microsoft_Office_Visio.png'
import planner from '../../img/Microsoft_Office_Planner.png'
import sharePoint from '../../img/Microsoft_Office_SharePoint.png'
import toDo from '../../img/Microsoft_Office_ToDo.png'
import oneDrive from '../../img/Microsoft_Office_OneDrive.png'
import outlook from '../../img/Microsoft_Office_Outlook.png'
import excel from '../../img/Microsoft_Office_Excel.png'
import word from '../../img/Microsoft_Office_Word.png'
import photoDirector from '../../img/Microsoft_Office_PhotoDirector.png'
import powerDirector from '../../img/Microsoft_Office_PowerDirector.png'
import audioDirector from '../../img/Microsoft_Office_AudioDirector.png'
import colorDirector from '../../img/Microsoft_Office_ColorDirector.png'
import yellow_ellipse from '../../img/yellow_ellipse.png'
import red_ellipse from '../../img/red_ellipse.png'
import green_ellipse from '../../img/green_ellipse.png'
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import danger from '../../img/danger.png'
import exclamation from '../../img/exclamation.png'
import axios from "axios";
import authService from "../../services/auth.service";
import { log } from "console";

const columns2 = [
  {
    title: 'Ημερομηνία Υποβολής',
    dataIndex: 'date_submitted',
    key: 'date_submitted',
    width: 167
  },
  {
    title: 'Αριθμός Αίτησης',
    dataIndex: 'application_code',
    key: 'application_code',
    width: 168
  },
  {
    title: 'Κατάσταση Αίτησης',
    dataIndex: 'status',
    key: 'status',
    width: 185,
  }
];

const customNoDataLocale = {
  emptyText: (
    <div className={classes.noData}>
      <img src={exclamation} alt="exclamation" className={classes.exclamation} />
      <p>Δεν υπάρχουν εγγραφές</p>
    </div>
  ),
};
const Profile = () => {
  const { t } = useTranslation()
  const [suspension, setSuspension] = useState<boolean>(false);
  const { isOpenSuspension, isOpenSuspensionSuccess, toggleSuspension, toggleSuspensionSuccess } = useModal();
  const storedAccessToken = window.localStorage.getItem("access_token");
  const [user, setUser] = useState<{ lastName: string, email: string, enabled: boolean }>({ lastName: '-', email: '-', enabled: false });
  const [applications, setApplications] = useState<any>([])

  const onFinish = (values: any) => {
    alert(values["password"])
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleButtonClick = () => {
    toggleSuspension();
    setSuspension(!suspension);
    toggleSuspensionSuccess();
  }

  const renderStatus = (status: string) => {
    if (status === 'Σε εξέλιξη') {
      return <div className={classes.statusContainer}><img src={yellow_ellipse} alt={status} /> <p>  Σε εξέλιξη</p></div>;
    } else if (status === 'Απορρίφθηκε') {
      return <div className={classes.statusContainer}><img src={red_ellipse} alt={status} /> <p>  Μη εγκεκριμένη</p></div>;
    } else if (status === 'Εγκρίθηκε') {
      return <div className={classes.statusContainer}><img src={green_ellipse} alt={status} /> <p>  Εγκρίθηκε</p></div>;
    } else
      return status;
  };

  const getAdminUserById = async () => {
    const afm = window.localStorage.getItem('afm');
    const response = await axios.get(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search?tax_id=${afm}`);
    const applicationsWithFormattedDates = response.data.map((application: any) => ({
      ...application,
      date_submitted: formatDate(application.date_submitted), // Format the date here
    }));
    setApplications(applicationsWithFormattedDates);
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1 to get the correct month.
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`https://auth.dotsoft.gr/auth/admin/realms/EATA/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${storedAccessToken}`,
      },
    })
      .then((response) => response.json())
      .then(async (response) => {
        const user = response.filter((row: any) => {
          return row.attributes && row.attributes.taxid && row.attributes.taxid[0] === window.localStorage.getItem('afm');
        });
        setUser(user[0]);
        window.localStorage.setItem('id', user[0].id);

        // Call the function to get admin user by ID
        getAdminUserById();
      });
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {    
    const refreshIntervalId = setInterval(async () => {
      try {
        await authService.refreshAccessToken();
      } catch (error) {
        console.error('Error refreshing access token:', error);
      }
    }, 4 * 60 * 60 * 1000 + 58 * 60 * 1000); // 4 hours + 58 minutes
    // ...

    // Clean up the interval when the component is unmounted or when the first effect is re-run
    return () => clearInterval(refreshIntervalId);
}, [/* add any dependencies if needed */]);

  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
      <Modal isOpen={isOpenSuspension} toggle={toggleSuspension} step={1}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSuspension}>x</button></div>
        <div className={classes.message}>
          <h2>Είστε σίγουρος/ σίγουρη ότι θέλετε να τερματίσετε την συνδρομή σας;</h2>

          <div className={classes.formItemContainer}>

            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#263489' }} htmlType="submit" onClick={() => toggleSuspension()}>
              Άκυρο
            </Button>
            <Button type="primary" className={classes.submit} style={{ backgroundColor: '#ED5350' }} htmlType="submit" onClick={() => handleButtonClick()}>
              Ναι
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpenSuspensionSuccess} toggle={toggleSuspensionSuccess} step={2}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSuspensionSuccess}>x</button></div>
        <div className={classes.dangerContainer}>
          <img src={danger} alt="message" />
        </div>
        <div className={classes.message}>
          <p>Η συνδρομή σας έχει διακοπεί. Από 29/04/23 δεν θα έχετε πλέον πρόσβαση στις δωρεάν παροχές.</p>
        </div>
      </Modal>
      <div className={classes.container}>

        <div className={classes.fcontainer}>
          <h2>Προφίλ Επιχείρησης</h2>
          <Row>
            <Col xs={24} sm={24} md={17} lg={18} xl={18}>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Επωνυμία</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{user.lastName}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>ΑΦΜ</span>
                </Col>
                <Col xs={24} sm={14} md={5} lg={14} xl={16}>
                  <span className={classes.cres}>{window.localStorage.getItem("afm")}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Δραστηριότητα</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{applications.length > 0 ? applications[0].activity || '-' : '-'}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Διεύθυνση</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{applications.length > 0 ? applications[0].address + ' ' + applications[0].address_number || '-' : '-'}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>ΔΟΥ</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{applications.length > 0 ? applications[0].tax_office || '-' : '-'}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Τηλέφωνο Επικοινωνίας</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{applications.length > 0 ? applications[0].phone1 ? applications[0].phone1 : ' ' + applications[0].phone2 ? ', ' + applications[0].phone2 : '' || '-' : '-'}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Ε-mail</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{user.email}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={10} md={9} lg={10} xl={8}>
                  <span className={classes.clabel}>Microsoft ID</span>
                </Col>
                <Col xs={24} sm={14} md={15} lg={14} xl={16}>
                  <span className={classes.cres}>{applications.length > 0 ? applications[0].microsoft_id || '-' : '-'}</span>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className={classes.clabel} style={{ marginTop: 50 }}>Κατάσταση αίτησης</span>
              {/*<span className={classes.clabel2}>Κατάσταση αίτησης</span>
              <span className={classes.cres2}>{user.enabled ? 'Ενεργή' : 'Μη Ενεργή'}</span>
              <span className={classes.clabel2}>Διάρκεια</span>
              <span className={classes.cres2}>22/02/22-23/02/23</span>*/}
              <Table
                columns={columns2}
                className={classes.tableData}
                locale={customNoDataLocale}
                dataSource={applications}
                scroll={{ x: 'max-content', y: 'calc(100vh - 400px)' }}
              />
            </Col>

          </Row>

          {/* <br /><br /><br /> */}



          {/* <Row>
            <div className={classes.btnlink} onClick={toggleSuspension}>Διακοπή Συνδρομής</div>
          </Row> */}



        </div>
      </div>
      {/* {suspension && <div className={classes.notificationContainer}>
        <img src={danger} alt="danger" />
        <span>
          <strong>Υπενθύμιση:</strong>  Η συνδρομή σας έχει διακοπεί. Από 29/04/23 δεν θα έχετε πλέον πρόσβαση στις δωρεάν παροχές.
        </span>
      </div>} */}



      <div className={classes.containerPrograms}>
        <div className={classes.programs}>
          <span className={classes.benefitsTitle}>{t('Παροχές Προγράμματος')}</span>
          <br /><br />
          <div className={classes.gridContainer}>
            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ background: '#33C481' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={planner} alt="planner" />
                </div>
                <h3>Planner</h3>
                <span>
                  Εφαρμογή προγραμματισμού χρόνου εργασίας
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#3294E9' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={toDo} alt="To Do" />
                </div>
                <h3>To Do</h3>
                <span>
                  Εφαρμογή παρακολούθησης χρόνου εργασίας
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#28A8EA' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={oneDrive} alt="One Drive" />
                </div>
                <h3>One Drive</h3>
                <span>
                  Εφαρμογή διαμοιρασμού κοινού χώρου
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#37C5CF' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={sway} alt="Sway" />
                </div>
                <h3>Sway</h3>
                <span>
                  Εφαρμογή παρουσιάσεων & ψηφιακού μάρκετινγκ
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#7B83EB' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={teams} alt="Teams" />
                </div>
                <h3>Teams</h3>
                <span>
                  Εφαρμογή chat, τηλεδιάσκεψης και τηλεκπαίδευσης
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#28A8EA' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={outlook} alt="Outlook" />
                </div>
                <h3>Outlook</h3>
                <span>
                  Εφαρμογή αλληλογραφίας
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#D14424' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={powerPoint} alt="Power Point" />
                </div>
                <h3>Power Point</h3>
                <span>
                  Εφαρμογή παρουσιάσεων & ψηφιακού μάρκετινγκ
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#21A366' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={excel} alt="Excel" />
                </div>
                <h3>Excel</h3>
                <span>
                  Εφαρμογή λογιστικών φύλλων κι επεξεργασίας κειμένου
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#007DD7' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={word} alt="Word" />
                </div>
                <h3>Word</h3>
                <span>
                  Εφαρμογή επεξεργασίας κειμένου
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#CA64EA' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={oneNote} alt="One Note" />
                </div>
                <h3>One Note</h3>
                <span>
                  Εφαρμογή καταγραφής σημειώσεων
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundImage: 'linear-gradient(90deg, #41A1FB -2.62%, #7FFFB3 100%)' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={powerDirector} alt="PowerDirector" />
                </div>
                <h3>PowerDirector</h3>
                <span>
                  Εφαρμογή επεξεργασίας video
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundImage: 'linear-gradient(269.93deg, #F87B11 0.08%, #FDE678 101.44%)' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={photoDirector} alt="Photo Director" />
                </div>
                <h3>Photo Director</h3>
                <span>
                  Εφαρμογή επεξεργασίας εικόνας
                </span>
              </div>
            </div>

          </div>
        </div >
        <br />


        <div className={classes.programs}>
          <span className={classes.benefitsTitle}>{t('Επιπλέον Παροχές')}</span>
          <br /><br />
          <div className={classes.gridContainer}>
            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ background: '#2166C3' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={visio} alt="Visio" />
                </div>
                <h3>Visio</h3>
                <span>
                  Εφαρμογή δημιουργίας διαγραμμάτων
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundColor: '#1A9BA1' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={sharePoint} alt="Share Point" />
                </div>
                <h3>Share Point</h3>
                <span>
                  Διαδυκτιακή πλατφόρμα συνεργασίας
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundImage: 'linear-gradient(90deg, #9B5BFE -2.62%, #00C5FF 100%)' }}>
              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={audioDirector} alt="AudioDirector" />
                </div>
                <h3>AudioDirector</h3>
                <span>
                  Εφαρμογή επεξεργασίας ήχου
                </span>
              </div>
            </div>

            <div className={classes.gridbox}>
              <div className={classes.gridTop} style={{ backgroundImage: 'linear-gradient(269.93deg, #E0BF12 0.08%, #F33FC1 101.44%)' }}>

              </div>
              <div className={classes.gridBottom}>
                <div className={classes.iconContainer}>
                  <img src={colorDirector} alt="ColorDirector" />
                </div>
                <h3>ColorDirector</h3>
                <span>
                  Εφαρμογή παρακολούθησης χρόνου εργασίας
                </span>
              </div>
            </div>


          </div>
        </div >
        <br />

      </div >
    </>
  )
}

export default Profile
