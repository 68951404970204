import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../../components/Helmet/Helmet";
import classes from "./Application.module.css";
import "../../styles/button.less";
import {
  Button,
  Col,
  Row,
  Checkbox,
  Form,
  Input,
  Space,
  Radio,
  RadioChangeEvent,
  Modal,
  Upload,
  notification,
} from "antd";
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons";
import Modal1 from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import message from "../../img/message.png";
import infoIcon from "../../img/info.png";
import pclip from "../../img/pclip.png";
import jwt_decode from "jwt-decode";
import axios from "axios";
notification.config({
  placement: "top",
});

interface TokenPayload {
  sub: string;
  jti: string;
  iat: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
  exp: number;
  iss: string;
  aud: string;
}
interface User {
  email: string;
  attributes: {
    birthyear: string[];
    fathername: string[];
    firstname: string[];
    mothername: string[];
    taxid: string[];
    userid: string[];
  };
  createdTimestamp: number;
  disableableCredentialTypes: string[];
  emailVerified: boolean;
  enabled: boolean;
  firstName: string;
  id: string;
  company_name: string;
  lastName: string;
  notBefore: number;
  requiredActions: string[];
  totp: boolean;
  username: string;
}

interface Address {
  address: string;
  address_number: string;
  addresses: string;
  location: string;
  tax_office: string;
  valid_address: boolean;
  zipcode: string;
}

const Application = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [select, setSelect] = useState<string>("");

  const [value, setValue] = useState(1);
  const [user, setUser] = useState<User | null>(null);
  const [address, setAddress] = useState<Address | null>(null);
  const [nameId, setNameId] = useState<string>("");
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [file1, setFile1] = useState<File | undefined | null>(null);
  const [file2, setFile2] = useState<File | undefined | null>(null);
  const [file3, setFile3] = useState<File | undefined | null>(null);
  const [file4, setFile4] = useState<File | undefined | null>(null);
  const [file5, setFile5] = useState<File | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [generalError, setGeneralError] = useState<boolean>(false);

  const handleFileChange = (file: File, setter: Function) => {
    setter(file);
  };

  const handleFileCancel = (setter: Function) => {
    setter(null);
  };

  const getID = async () => {
    const requestBody = {
      email: user?.email,
      firstname: user?.attributes?.firstname[0],
      lastname: user?.lastName,
      fathername: user?.attributes.fathername[0],
      company_name: user?.lastName ? user?.lastName : "null",
      tax_id: user?.attributes.taxid[0],
      phone1: "null",
      phone2: "null",
      address: address?.address ?? "",
      address_number: address?.address_number ?? "",
      zipcode: address?.zipcode ?? "",
      location: address?.location ?? "",
    };

    let regId =
      (await registerId(requestBody)) || (await parseSetUserToValues());
    console.log("regId: ", regId);

    const provided_afm = window.localStorage.getItem("afm");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_NAME}/api/Auth/SearchAddress/` +
          provided_afm,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("acctoken")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address data");
      }

      const data = await response.json();
      console.log("Address: ", data);
      setAddress(data);
      window.localStorage.setItem("address", JSON.stringify(data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [notificationVisible, setNotificationVisible] = useState(false);

  const showNotification = () => {
    setNotificationVisible(true);
  };

  const closeNotification = () => {
    setNotificationVisible(false);
  };

  const navigateToProfile = () => {
    closeNotification();
    navigate("/profile");
  };

  const openFailNotification = () => {
    notification.open({
      message: `Δυστυχώς, η αίτησή σας δεν καταχωρίθηκε επιτυχώς!`,
      description: "Παρακαλούμε ξαναπροσπαθήστε ή επικοινωνήστε μαζί μας.",
      duration: 0,
      placement: "top",
      type: "error",
    });
  };

  const onFinish = async (values: any) => {
    setFormSubmitted(true);
    setGeneralError(false);
    try {
      await form.validateFields();
    } catch (error) {}

    setLoading(true);
    const requestBody = {
      email: user?.email,
      firstname: user?.attributes?.firstname[0],
      lastname: user?.lastName,
      fathername: user?.attributes.fathername[0],
      company_name: user?.lastName ? user?.lastName : "",
      tax_id: user?.attributes.taxid[0],

      phone1: values.home_phone_number,
      phone2: values.mobile_phone_number ? values.mobile_phone_number : "",
      address: address?.address ?? initialValues?.address ?? "",
      address_number:
        address?.address_number ?? initialValues?.address_number ?? "",
      zipcode: address?.zipcode ?? initialValues?.zipcode ?? "",
      location: address?.location ?? initialValues?.location ?? "",
      tax_office: address?.tax_office ?? initialValues?.tax_office ?? "",
      region: values?.region ?? "",
    };

    const params = {
      id: nameId,
      activity_id: values["activity"],
      comments: values["comment"],
    };

    console.log("params.id: ", params.id);

    // let regId =
    //   (await registerId(requestBody)) || (await parseSetUserToValues());
    // console.log("regId: ", regId);
    let regId = (await updateId(requestBody)) || (await parseSetUserToValues());
    console.log("regId: ", regId);

    console.log("nameId: ", nameId);

    // const tokenEATAMS = window.localStorage.getItem('tokenEATAMS');
    // if (tokenEATAMS && typeof tokenEATAMS === "string") {
    //   console.log('tokenn: ', tokenEATAMS);

    //   const decodedToken = jwt_decode<TokenPayload>(tokenEATAMS!);
    //   const nameIdentifier = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    //   console.log("nameIdentifier: ", nameIdentifier);
    // }
    // After getting the required parameters, create the FormData object
    const formData = await new FormData();
    await formData.append(
      "id",
      (await window.localStorage.getItem("nameID"))
        ? await window.localStorage.getItem("nameID")!
        : regId!
    );
    await formData.append("activity_id", params.activity_id);
    await formData.append("comments", params.comments);
    if (file1) {
      await formData.append("file1", file1);
    }
    if (file2) {
      await formData.append("file2", file2);
    }
    if (file3) {
      await formData.append("file3", file3);
    }
    if (file4) {
      await formData.append("file4", file4);
    }
    if (file5) {
      await formData.append("file5", file5);
    }

    console.log("formData: ", formData);

    console.log("nameId: ", nameId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Create`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem("acctoken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data); 

      setApplicationId(response.data);
      if (response.data && response.data.status !== 409) {
        onReset();
        setLoading(false);
        setFormSubmitted(false);
        showNotification();
      } else {
        openFailNotification();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setGeneralError(true);
    console.log("Failed:", errorInfo);
  };
  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const registerId = async (body: any) => {
    await fetch(`${process.env.REACT_APP_SERVER_NAME}/api/Auth/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        console.log("tokenEATAMS: ", response);
        if (
          response.access_token &&
          typeof response.access_token === "string"
        ) {
          await window.localStorage.setItem(
            "tokenEATAMS",
            response.access_token
          );
          const token = response.access_token;
          console.log("tokenn: ", token);
          if (!token) {
            return window.localStorage.getItem("access_token");
          }
          const decodedToken = jwt_decode<TokenPayload>(token);
          const nameIdentifier =
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];
          console.log(nameIdentifier);
          await window.localStorage.setItem("nameID", nameIdentifier);
          setNameId(nameIdentifier);
          return nameIdentifier;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return null;
  };

  const updateId = async (body: any) => {
    const provided_afm = await window.localStorage.getItem("afm");

    await fetch(
      `${process.env.REACT_APP_SERVER_NAME}/api/Auth/Update/` + provided_afm,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("acctoken")}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        console.log("tokenEATAMS: ", response);
        if (
          response.access_token &&
          typeof response.access_token === "string"
        ) {
          await window.localStorage.setItem(
            "tokenEATAMS",
            response.access_token
          );
          const token = response.access_token;
          console.log("tokenn: ", token);
          if (!token) {
            return window.localStorage.getItem("access_token");
          }
          const decodedToken = jwt_decode<TokenPayload>(token);
          const nameIdentifier =
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];
          console.log(nameIdentifier);
          await window.localStorage.setItem("nameID", nameIdentifier);
          setNameId(nameIdentifier);
          return nameIdentifier;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return null;
  };

  const onReset = () => {
    form.resetFields();
    setSelect("");
    handleFileCancel(setFile1);
    handleFileCancel(setFile2);
    handleFileCancel(setFile3);
    handleFileCancel(setFile4);
    handleFileCancel(setFile5);
    parseSetUserToValues();
  };

  const searchAddress = () => {
    const afm = window.localStorage.getItem("afm");
    fetch(
      `${process.env.REACT_APP_SERVER_NAME}/api/Auth/SearchAddress/${afm}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("acctoken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        form.setFieldsValue({
          address: data.address,
          address_number: data.address_number,
          zipcode: data.zipcode,
          location: data.location
        });
      });
    // form.resetFields();
  };

  const { isOpenSearch, toggleSearch } = useModal();

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    afm: "",
    activity: null,
    address: "",
    address_number: "",
    zipcode: "",
    location: "",
    region: "",
    doy: "",
    home_phone_number: "",
    mobile_phone_number: "",
    email: "",
    comment: "",
    tax_office: "",
    file_list: [],
    agreement: false,
    agreement2: false,
    agreement3: false,
  });

  const parseSetUserToValues = async () => {
    try {
      const currentUser = window.localStorage.getItem("user");

      if (currentUser !== null) {
        const parsedUser: User = JSON.parse(currentUser);
        console.log("parsedUser: ", parsedUser);

        setUser(parsedUser);
        if (parsedUser) {
          form.setFieldsValue({
            email: parsedUser.email,
            company_name: parsedUser.lastName,
            afm: parsedUser.attributes.taxid[0],
          });
          setUser(parsedUser);
          setInitialValues((prevValues) => ({
            ...prevValues,
          }));
        }
        return parsedUser.id;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getId = async () => {
      await parseSetUserToValues();
      console.log("user: ", localStorage.getItem("user"));
      const userJSON = localStorage.getItem("user");

      const user: User | null = userJSON ? JSON.parse(userJSON) : null;
      const requestBody = {
        email: user?.email,
        firstname: user?.attributes?.firstname[0],
        lastname: user?.lastName,
        fathername: user?.attributes.fathername[0],
        company_name: user?.lastName ? user?.lastName : "",
        tax_id: user?.attributes.taxid[0],
        phone1: "",
        phone2: "",
        address: address?.address ?? "",
        address_number: address?.address_number ?? "",
        zipcode: address?.zipcode ?? "",
        location: address?.location ?? "",
      };
      console.log(requestBody);

      let regId = await registerId(requestBody);
      console.log("regId: ", regId);
      await searchAddress();

      // call the function

      const provided_afm = window.localStorage.getItem("afm");

      fetch(
        `${process.env.REACT_APP_SERVER_NAME}/api/Auth/GetTokenByTaxId/` +
          provided_afm,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then(async (token) => {
          console.log("Token: ", token);
          await window.localStorage.setItem("token", token);
          await window.localStorage.setItem("acctoken", token.access_token);

          const decodedToken = jwt_decode<TokenPayload>(token.access_token);
          const nameIdentifier =
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ];
          console.log(nameIdentifier);
          await window.localStorage.setItem("nameID", nameIdentifier);
          await setNameId(nameIdentifier);
        });

      const fetchAddress = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_NAME}/api/Auth/SearchAddress/` +
              window.localStorage.getItem("afm"),
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem(
                  "acctoken"
                )}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch address data");
          }

          const data = await response.json();
          console.log("Address: ", data);
          await form.setFieldsValue({
            address: data.address,
            address_number: data.address_number,
            zipcode: data.zipcode,
            location: data.location,
            valid_address: data.valid_address,
          });

          // setAddress(data);
          await setInitialValues((prevValues) => ({
            ...prevValues,
            address: data.address,
            address_number: data.address_number,
            zipcode: data.zipcode,
            location: data.location,
            valid_address: data.valid_address,
          }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchAddress();
    };
    getId();
  }, [address]);

  return (
    <>
      {searchAddress()}
      {contextHolder}
      <Modal
        title="Η αίτησή σας καταχωρίθηκε επιτυχώς!"
        visible={notificationVisible}
        onCancel={closeNotification}
        footer={[
          <Button key="profile" type="primary" onClick={navigateToProfile}>
            Προβολή Προφίλ
          </Button>,
        ]}
      >
        <p>
          Μπορείτε να ενημερωθείτε για την εξέλιξή του αιτήματος στο προφίλ σας,
          ευχαριστούμε!
        </p>
      </Modal>

      <HelmetComponent
        title={t("ΕΑΤΑ") || undefined}
        descrition={t("ΕΑΤΑ") || undefined}
      />
      <div className={classes.pageContainer}>
        {/* step={0} = Contact type modal */}
        <Modal1 isOpen={isOpenSearch} toggle={toggleSearch} step={0}>
          <div className={classes.overlay_header}>
            <button className={classes.cross} onClick={toggleSearch}>
              x
            </button>
          </div>
          <div className={classes.messageContainer}>
            <img src={message} alt="message" />
          </div>
          <div className={classes.message}>
            <p>
              Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα σας απαντήσουμε
              το συντομότερο δυνατό.
            </p>
          </div>
        </Modal1>
        <div className={classes.container}>
          <div className={classes.fcontainer}>
            <Form
              form={form}
              labelCol={{ xs: 24, sm: 24, md: 6, lg: 8, xl: 8 }}
              wrapperCol={{ xs: 24, sm: 24, md: 14, lg: 13, xl: 11 }}
              layout="horizontal"
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="on"
            >
              <div className={classes.formItemContainer}>
                <Form.Item label=" " name=" " className={classes.formItem}>
                  <h2>Αίτηση Συμμετοχής Ωφελούμενων</h2>
                  <span className={classes.subName}>
                    * Τα πεδία/ δικαιολογητικά με αυτή την ένδειξη είναι
                    υποχρεωτικά.
                  </span>
                </Form.Item>

                <Form.Item
                  label="Επωνυμία επιχείρησης*"
                  name="company_name"
                  className={classes.formItem}
                  rules={[
                    { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="ΑΦΜ*"
                  name="afm"
                  initialValue={user ? window.localStorage.getItem("afm") : ""}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Το πεδίο είναι υποχρεωτικό",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Δραστηριότητα Επιχείρησης*"
                  name="activity"
                  className={classes.formItemActivity}
                  rules={[
                    { required: true, message: "Το πεδίο είναι υποχρεωτικό" },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Radio.Group
                    onChange={onChangeRadio}
                    value={value}
                    style={{ width: "100%" }}
                    className={classes.radioGroup}
                  >
                    <Space
                      direction="vertical"
                      className={classes.RadioContainer}
                    >
                      <Radio className={classes.radio} value={1}>
                        Τεχνολογίες Πληροφορικής & Επικοινωνίας (ΤΠΕ)
                      </Radio>
                      <Radio className={classes.radio} value={2}>
                        Εφοδιαστική Αλυσίδα / Logistics
                      </Radio>
                      <Radio className={classes.radio} value={3}>
                        Οικονομία - Διοίκηση
                      </Radio>
                      <Radio className={classes.radio} value={4}>
                        Πωλήσεις - Marketing
                      </Radio>
                      <Radio className={classes.radio} value={5}>
                        Τεχνικά Επαγγέλματα
                      </Radio>
                      <Radio className={classes.radio} value={6}>
                        Τρόφιμα - Αγροδιατροφή
                      </Radio>
                      <Radio className={classes.radio} value={7}>
                        Τουρισμός
                      </Radio>
                      <Radio className={classes.radio} value={8}>
                        Περιβάλλον - Ανακύκλωση - Ενέργεια
                      </Radio>
                      <Radio className={classes.radio} value={9}>
                        Υγεία
                      </Radio>
                      <Radio className={classes.radio} value={10}>
                        Άλλο
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Διεύθυνση Επιχείρησης*"
                  name="address"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Η διεύθυνσή σας είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} disabled={true} />
                </Form.Item>
                <Form.Item
                  label="Αριθμός Διεύθυνσης Επιχείρησης*"
                  name="address_number"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Η διεύθυνσή σας είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} disabled={true} />
                </Form.Item>

                <Form.Item
                  label="ΤΚ"
                  name="zipcode"
                  className={classes.formItem}
                  rules={[
                    { required: true, message: "Ο ΤΚ είναι υποχρεωτικό πεδίο" },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} disabled={true} />
                </Form.Item>

                <Form.Item
                  label="Πόλη*"
                  name="location"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Η πόλη είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} disabled={true} />
                  {address?.valid_address === false && (
                    <>
                      <br />
                      <br />
                      <div
                        className={classes.warning}
                        style={{ textAlign: "start" }}
                      >
                        Δεν μπορείτε να συμμετάσχετε! ???
                      </div>
                    </>
                  )}
                </Form.Item>

                <Form.Item
                  label="Περιφερειακή Ενότητα (Νομος)*"
                  name="region"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Η Περιφερειακή Ενότητα είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="ΔΟΥ*"
                  name="doy"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Η ΔΟΥ είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Σταθερό Τηλέφωνο*"
                  name="home_phone_number"
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: "Το Σταθερό Τηλέφωνο είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Κινητό Τηλέφωνο"
                  name="mobile_phone_number"
                  className={classes.formItem}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Email*"
                  name="email"
                  className={classes.formItem}
                  rules={[
                    {
                      type: "email",
                      message: "Δεν έχει συμπληρωθεί σωστά. Ξαναπροσπαθήστε",
                    },
                    {
                      required: true,
                      message: "Το email είναι υποχρεωτικό πεδίο",
                    },
                  ]}
                  colon={false}
                  required={false}
                >
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Σχόλιο"
                  name="comment"
                  className={classes.formItem}
                >
                  <Input.TextArea
                    className={classes.inputTextArea}
                    style={{ height: 120, resize: "none" }}
                    maxLength={1200}
                  />
                </Form.Item>

                <Form.Item label=" " name=" " className={classes.formItem}>
                  <h3 style={{ marginBottom: 0 }}>
                    Παρακαλούμε προσθέσετε τα ακόλουθα δικαιολογητικά για να
                    ολοκληρώσετε την αίτησή σας:
                  </h3>
                </Form.Item>

                {/* <Form.Item label="" name="file1" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      // Extract the native File object from UploadFile<any>
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile1);
                    }}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Πιστοποιητικό Μητρώου Επιχειρήσεων</Button>
                  </Upload>
                  {file1 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file1.name}
                    </span>
                  )}
                </Form.Item> */}

                {/* <Form.Item
                  label=""
                  name="file1"
                  className={classes.fileList}>
                  <Button className={classes.formItem2} icon={<UploadOutlined />}>
                    Πιστοποιητικό Μητρώου Επιχειρήσεων
                  </Button>
                </Form.Item>

                <Form.Item
                  label=""
                  name="file1"
                  className={classes.fileList}>
                  <Button className={classes.formItem2} icon={<UploadOutlined />}>
                    Πιστοποιητικό Ισχύουσας Εκπροσώπησης
                  </Button>
                </Form.Item>

                <Form.Item
                  label=""
                  name="file1"
                  className={classes.fileList}>
                  <Button className={classes.formItem2} icon={<UploadOutlined />}>
                    Καταστατικό / ΦΕΚ
                  </Button>
                </Form.Item>

                <Form.Item
                  label=""
                  name="file1"
                  className={classes.fileList}>
                  <Button className={classes.formItem2} icon={<UploadOutlined />}>
                    Φορολογική Ενημερότητα
                  </Button>
                </Form.Item>

                <Form.Item
                  label=""
                  name="file1"
                  className={classes.fileList}>
                  <Button className={classes.formItem2} icon={<UploadOutlined />}>
                    Ασφαλιστική Ενημερότητα
                  </Button>
                </Form.Item> */}

                <Form.Item label="" name="file1" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile1);
                    }}
                    showUploadList={false}
                  >
                    <Button className={`${classes.formItem2} no-ripple`}>
                      <img src={pclip} alt="Paper clip" />
                      Πιστοποιητικό Μητρώου Επιχειρήσεων
                    </Button>
                  </Upload>
                  {file1 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file1.name}
                      <Button
                        size="small"
                        onClick={() => handleFileCancel(setFile1)}
                        style={{ marginLeft: 10, border: 0 }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )}
                </Form.Item>
                <Form.Item label="" name="file2" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile2);
                    }}
                    showUploadList={false}
                  >
                    <Button className={classes.formItem2}>
                      <img src={pclip} alt="Paper clip" />
                      Πιστοποιητικό Ισχύουσας Εκπροσώπησης *
                    </Button>
                  </Upload>
                  {file2 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file2.name}
                      <Button
                        size="small"
                        onClick={() => handleFileCancel(setFile2)}
                        style={{ marginLeft: 10, border: 0 }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )}
                </Form.Item>
                <Form.Item label="" name="file3" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile3);
                    }}
                    showUploadList={false}
                  >
                    <Button className={classes.formItem2}>
                      <img src={pclip} alt="Paper clip" />
                      Καταστατικό / ΦΕΚ *
                    </Button>
                  </Upload>
                  {file3 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file3.name}
                      <Button
                        size="small"
                        onClick={() => handleFileCancel(setFile3)}
                        style={{ marginLeft: 10, border: 0 }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )}
                </Form.Item>
                <Form.Item label="" name="file4" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile4);
                    }}
                    showUploadList={false}
                  >
                    <Button className={classes.formItem2}>
                      <img src={pclip} alt="Paper clip" />
                      Φορολογική Ενημερότητα *
                    </Button>
                  </Upload>
                  {file4 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file4.name}
                      <Button
                        size="small"
                        onClick={() => handleFileCancel(setFile4)}
                        style={{ marginLeft: 10, border: 0 }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )}
                </Form.Item>
                <Form.Item label="" name="file5" className={classes.fileList}>
                  <Upload
                    className={classes.formItem2}
                    beforeUpload={() => false}
                    onChange={(info) => {
                      const { fileList } = info;
                      const nativeFile = fileList[0].originFileObj as File;
                      handleFileChange(nativeFile, setFile5);
                    }}
                    showUploadList={false}
                  >
                    <Button className={classes.formItem2}>
                      <img src={pclip} alt="Paper clip" />
                      Ασφαλιστική Ενημερότητα *
                    </Button>
                  </Upload>
                  {file5 && (
                    <span className={classes.requiredFile}>
                      <br />
                      <br />
                      <PaperClipOutlined /> {file5.name}
                      <Button
                        size="small"
                        onClick={() => handleFileCancel(setFile5)}
                        style={{ marginLeft: 10, border: 0 }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </span>
                  )}
                </Form.Item>
              </div>
              <br />
              <div className={classes.formItemContainer}>
                <div className={classes.checkboxContainer}>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    style={{ marginBottom: 40 }}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(new Error("Υποχρεωτικό")),
                      },
                    ]}
                  >
                    <Checkbox className={classes.checkbox}>
                      Δηλώνω ότι συγκατατίθεμαι για τη συλλογή, τη χρήση και την
                      επεξεργασία των παραπάνω στοιχείων και προσωπικών
                      δεδομένων, σύμφωνα με τους όρους και τις προϋποθέσεις που
                      θέτει η Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="agreement3"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    style={{ marginBottom: 60 }}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(new Error("Υποχρεωτικό")),
                      },
                    ]}
                  >
                    <Checkbox className={classes.checkbox}>
                      Ακόμα, δηλώνω ότι συγκατατίθεμαι για την διάθεση
                      πληροφοριών σχετικά με τα υποκαταστήματα. ???
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="agreement2"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    style={{ marginBottom: 60 }}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(new Error("Υποχρεωτικό")),
                      },
                    ]}
                  >
                    <Checkbox className={classes.checkbox}>
                      Επίσης, δηλώνω ότι συγκατατίθεμαι για την υποβολή της
                      ανωτέρω Αίτησης στην ειδική ιστοσελίδα εκάστου δικαιούχου
                      φορέα προκειμένου να συμμετάσχω στο πρόγραμμα.
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>

              <div className={classes.formItemContainer1}>
                <Row>
                  <Col xs={24} sm={24} md={3} lg={2} xl={2}>
                    <img src={infoIcon} alt="info" />
                  </Col>
                  <Col xs={24} sm={24} md={21} lg={22} xl={22}>
                    <p className={classes.alert}>
                      Το εν λόγω πρόγραμμα εντάσεται στα πλαίσια του κανόνα De
                      Minimis και χρηματοδοτείται μέσω του Πληροφοριακού
                      Συστήματος Σώρευσης Κρατικών Ενισχύσεων Ήσσονος Σημασίας
                      (Sorefsis.gr), με ποσό ενίσχυσης έως ΧΧΧ,00 €. Το ακριβώς
                      ποσό θα υπολογιστεί και θα σας επικοινωνηθεί μετά την
                      υποβολή της αίτησης.
                    </p>
                    {/* <Alert message="" type="info" showIcon /> */}
                  </Col>
                </Row>
              </div>

              <div className={classes.buttons}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Space>
                    <Row>
                      <Col>
                        <Button
                          htmlType="button"
                          onClick={onReset}
                          className={classes.reset}
                        >
                          Καθαρισμός
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className={classes.submit}
                          htmlType="submit"
                          disabled={loading || address?.valid_address === false}
                        >
                          Υποβολή Αίτησης
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>
                {generalError && (
                  <div className={classes.warning}>
                    Παρακαλούμε συμπληρώστε όλα τα απαραίτητα πεδία!
                  </div>
                )}
                {address?.valid_address === false && (
                  <div className={classes.warning}>
                    Δεν μπορείτε να συμμετάσχετε! ???
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Application;
