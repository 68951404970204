import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./ApplicationForm.module.css"
import { Button, Form, Input, Radio, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useState } from "react";

const ApplicationForm = () => {
  const { t } = useTranslation()
  const { TextArea } = Input;
  const token = window.localStorage.getItem("form_token");
  const [activity, setActivity] = useState(1);
  const accessToken = window.localStorage.getItem("access_token");

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setActivity(e.target.value);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onFinish = (values: any) => {
    console.log('here too');

    const formData = new FormData();
    formData.append('Id', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dnZWRJbkFzIjoiYWRtaW4iLCJpYXQiOjE0MjI3Nzk2Mzh9.gzSraSYS8EXBxLN_oWnFSRgCzcmJmMjLiuyu5CSpyHI');
    formData.append('activity_id', '1');
    formData.append('comments', 'This is a test form');

    const file = null;
    if (file) {
      formData.append('file', file);
    }

    fetch(`${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Create`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        // Handle error
        // this.refreshToken(window.location.href)
      });

    /*     const params_register = {
           email:'mntetsika@comitech.gr',
           firstname:'Mirto',
           lastname:'Ntetsika',
           fathername:'Ant',
           company_name:'Dotosoft',
           tax_id:'123456789',
           phone1:'1234',
           phone2:'12345',
           address:'Ermou',
           address_number:'4',
           zipcode:'123',
           location:'Athens'
       };
   
       fetch(`https://eata-admin.dotsoft.gr/eatams/api/Auth/Register`, {
         method: 'POST',
         headers: new Headers({
             'Content-Type': 'application/json',
         }),
         body: JSON.stringify( params_register )
     })
         .then(response => {
             return response.json()
         })
         .then(data => {
           console.log(data); 
             console.log('lala');   
               
               const params_form = {
           Id:'65c558e8-3ced-444d-ad39-f89ef7804ba6' ,
           activity_id:1,
           comments:'This is a test form',
           file:null
           };
   
             fetch(`https://eata-admin.dotsoft.gr/eatams/api/ApplicationForm/Create`, {
                 
             method: 'POST',
                 headers: {   
                     'Content-Type' : 'multipart/form-data', 
                 },
                 body: JSON.stringify( params_form )
             })
                 .then(response => {
                     return response.json()
                 })
         })
         .catch((error) => {
             console.log(error)
             // this.refreshToken(window.location.href)
         });*/

  }
  /*    const params = {
        Id: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiJhMWQyNGZjYS0zN2JkLTQxYjktYmZmOS05YjdmNmJmNzA3NzEiLCJpYXQiOiIzMS8wNS8yMDIzIDExOjE5OjU5IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiJhMmE2NDY1Ni1hOTIzLTQxOGYtYTE1ZC01NzBmYjVmZTIzOGYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoibW50ZXRzaWthQGRvdHNvZnQuZ3IiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJtbnRldHNpa2FAZG90c29mdC5nciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNjg1NTIyOTk5LCJpc3MiOiJKV1RORVRDb3JlQXV0aGVudGljYXRpb25TZXJ2ZXIiLCJhdWQiOiJKV1RTZXJ2aWNlUmVhY3RDbGllbnQifQ.tAz-8XGBY5Thgn7PVsxvo7DW0mesqU313Xsc8jh-1X0',
        activity_id: 1,
        comments:'this is a test comment',
        file:'' 
    };
  
        fetch(`https://eata-admin.dotsoft.gr/eatams/api/ApplicationForm/Create`, {
                
            method: 'POST',
                headers: {   
                    'Content-Type' : 'application/json', 
                },
                body: JSON.stringify( params )
            })
                .then(response => {
                    return response.json()
                })
        
        const onFinishFailed = (errorInfo:any) => {
          console.log('Failed:', errorInfo);
        };
  
        console.log('tax_id:', tax_id);
      */
  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
      <div className={classes.container}>
        <h4>{accessToken ? accessToken : "Not Found"}</h4>
        <h2>{t("Αίτηση Συμμετοχής Ωφελούμενου")}</h2>
        <div className={classes.fcontainer1}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Επωνυμία επιχείρησης *"
              name="company_name"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValue='dotsoft'
              rules={[
                {
                  required: true,
                  message: 'Please input your company name!',
                },
              ]}
            >
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              label="ΑΦΜ"
              name="tax_id"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValue='1234567'
              rules={[
                {
                  required: true,
                  message: 'Please input your taxis_id!',
                },
              ]}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="Δραστηριότητα Επιχείρησης"
              name="activity">
              <Radio.Group onChange={onChange} value={activity}>
                <Space direction="vertical">
                  <Radio value={1}>Τεχνολογίες Πληροφορικής & Επικοινωνίας (ΤΠΕ)</Radio>
                  <Radio value={2}>Εφοδιαστική Αλυσίδα / Logistics </Radio>
                  <Radio value={3}>Οικονομία - Διοίκηση</Radio>
                  <Radio value={4}>Τεχνικά Επαγγέλματα</Radio>
                  <Radio value={5}>Τρόφιμα - Αγροδιατροφή</Radio>
                  <Radio value={6}>Τουρισμός</Radio>
                  <Radio value={7}>Περιβάλλον - Ανακύκλωση - Ενέργεια</Radio>
                  <Radio value={8}>Υγεία</Radio>
                  <Radio value={9}>Άλλο</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Διεύθυνση Επιχείρησης"
              name="address"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValue={token}
              rules={[
                {
                  required: true,
                  message: 'Please input your address!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="ΤΚ"
              name="zipcode"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Πόλη"
              name="city"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Περιφερειακή Ενότητα (Νομος)"
              name="municipality"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ΔΟΥ"
              name="doi"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Σταθερό Τηλέφωνο"
              name="phone1"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Κινητό Τηλέφωνο"
              name="phone2"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="email"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Σχόλια"
              name="comments"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" className={classes.btn}>
                Υποβολή Αίτησης
              </Button>
            </Form.Item>


          </Form>
        </div>
      </div>
    </>
  )
}

export default ApplicationForm
