import { useState } from "react";

export default function useModal() {
  const [isOpenSearch, setisOpenSearch] = useState(false);
  const [isOpenSearchResults, setisOpenSearchResults] = useState(false);
  const [isOpenResults, setisOpenResults] = useState(false);
  const [isOpenFailed, setisOpenFailed] = useState(false);
  const [isOpenSuspension, setisOpenSuspension] = useState(false);
  const [isOpenSuspensionSuccess, setisOpenSuspensionSuccess] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [isOpenEditSuccess, setisOpenEditSuccess] = useState(false);
  const [isOpenUpload, setisOpenUpload] = useState(false);
  const [uploadFileData, setUploadFileData] = useState<File | null>(null);
  const [uploadFileName, setUploadFileName] = useState("");
  const [isOpenUploadSuccess, setisOpenUploadSuccess] = useState(false);
  const [isOpenDeletion, setisOpenDeletion] = useState(false);
  const [isOpenDeletionSuccess, setisOpenDeletionSuccess] = useState(false);


  const toggleSearch = () => {
    setisOpenSearch(!isOpenSearch);
  };
  const toggleSearchResults = () => {
    setisOpenSearchResults(!isOpenSearchResults);
  };
  const toggleResults = () => {
    setisOpenResults(!isOpenResults);
  };
  const toggleFailed = () => {
    setisOpenFailed(!isOpenFailed);
  };
  const toggleSuspension = () => {
    setisOpenSuspension(!isOpenSuspension);
  };
  const toggleSuspensionSuccess = () => {
    setisOpenSuspensionSuccess(!isOpenSuspensionSuccess);
  };
  const toggleEdit = () => {
    setisOpenEdit(!isOpenEdit);
  };
  const toggleEditSuccess = () => {
    setisOpenEditSuccess(!isOpenEditSuccess);
  };
  const toggleUpload = (fileName:string) => {
    setUploadFileName(fileName); // Set the file name for the Upload modal
    setisOpenUpload(!isOpenUpload);
  };
  const toggleUploadSuccess = () => {
    setisOpenUploadSuccess(!isOpenDeletionSuccess);
  };
  const toggleDeletion = () => {
    setisOpenDeletion(!isOpenDeletion);
  };
  const toggleDeletionSuccess = () => {
    setisOpenDeletionSuccess(!isOpenDeletionSuccess);
  };

  const resetUploadData = () => {
    setUploadFileData(null);
  };

  return {
    isOpenSearch,
    toggleSearch,
    isOpenSearchResults,
    toggleSearchResults,
    isOpenResults,
    toggleResults,
    isOpenFailed,
    toggleFailed,
    isOpenSuspension,
    toggleSuspension,
    isOpenSuspensionSuccess,
    toggleSuspensionSuccess,
    isOpenEdit,
    toggleEdit,
    isOpenEditSuccess,
    toggleEditSuccess,
    isOpenUpload,
    toggleUpload,
    uploadFileName,
    isOpenUploadSuccess,
    toggleUploadSuccess,
    isOpenDeletion,
    toggleDeletion,
    isOpenDeletionSuccess,
    toggleDeletionSuccess,
    uploadFileData,
    resetUploadData
  };
}
