import { Component, useContext  } from "react";
import React, { useState, useEffect } from "react"
import { RightOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from "./LoginAdmin.module.css"
import { Button, Col, Row, Input } from 'antd';
import { useTranslation } from "react-i18next"
import LoginForm from '../Login/LoginForm';
import ggps from '../../img/ggps.png'
import logo from '../../img/logo.png'
import etpa from '../../img/ETPA.png'

type Props = {};

type State = {
    redirect: string | null,
    username: string,
    password: string,
    loading: boolean,
    message: string,
    afm: string
};

export default class LoginAdmin extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirect: null,
            username: "",
            password: "",
            loading: false,
            message: "",
            afm: "" // Initialize afm property
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        // window.location.reload();
    }

    handleLogin(formValue: { username: string; password: string }) {

    }

    onChange = (event: any) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

        if (sanitizedValue.length === 9) {
            window.localStorage.setItem('afm', sanitizedValue.toString());
            console.log('Value stored in localStorage: ' + sanitizedValue);
        } else {
            console.log('Error: Input must contain exactly 9 digits');
        }

        this.setState({ afm: sanitizedValue });
    };

    render() {
        const { afm } = this.state;

        return (
            <>
                <div className={classes.container}>
                    {/* <Col lg={12} md={12} sm={24} xs={24}> */}
                    <div className={classes.login}>
                        <div className={classes.title}>
                            <Row>
                                <Col xs={24} sm={11} md={11} xl={11}>
                                    <img src={logo} className={classes.logo} alt="logo" />
                                </Col>
                                <Col xs={24} sm={13} md={13} xl={13}>
                                    <h3>Πρόγραμμα Ψηφιακού Μετασχηματισμού Επιχειρήσεων</h3>
                                </Col>
                            </Row>
                        </div>
                        <div className={classes.separator}></div>
                        <LoginForm />
                    </div>
                    <img className={classes.etpa} src={etpa} alt="espa" />
                    {/* </Col> */}
                    {/* <Col lg={12} md={12} sm={24} xs={24}>
                        <div className={classes.login}>
                            <div className={classes.title}>
                                <h3>Είσοδος με κωδικούς taxinet (για επιχειρήσεις - διακαιούχους)</h3>
                                <img src={ggps} />

                                <Row>
                                    <Col xl={2}>
                                        <p style={{ fontSize: 13 }}>ΑΦΜ</p>
                                    </Col>
                                    <Col>
                                        <Input
                                            name="afm"
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                    <Col xl={12}>
                                        <button
                                            disabled={afm.length < 9}
                                            type="button"
                                            className="ant-btn css-dev-only-do-not-override-yp8pcc ant-btn-primary ant-btn-lg lbtn"
                                        >
                                            <span role="img" aria-label="download" className="anticon anticon-download"></span>
                                            <span>
                                                <a href="/login">Σύνδεση</a>
                                            </span>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col> */}
                </div>
            </>
        );
    }
}