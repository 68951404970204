import React, { useContext, useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./LoginRequestPassword.module.css";
import LoginForm from "./LoginForm";
import ggps from "../../img/ggps.png";
import logo from "../../img/logo.png";
import etpa from '../../img/ETPA.png'
import { AuthContext } from '../../context/AuthContext';
import { Button, Divider, notification, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import axios from "axios";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { isLoggedOut } = useContext(AuthContext);
  const [error, setError] = useState<string>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [submitted, setSubmitted] = useState(false); // New state variable to track form submission

  // ... (existing code)
  const [confirmPassword, setConfirmPassword] = useState(""); // Add state for confirm password

  const handleNavigation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/Auth/ResetPassword`,
        {
          email: email,
          token: token,
          password: confirmPassword,
        }
      );

      // If the request is successful, show the success notification
      openNotification('top');
    } catch (error: any) {
      console.error('Error reseting the password:', error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // Set submitted to true to display error message on form submission
    setSubmitted(true);
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: <span className={classes.customNotification}>Η επαναφορά κωδικού ολοκληρώθηκε επιτυχώς!</span>,
      placement,
      className: `${classes.customNotification}`,
      duration: 0
    });
  };

  useEffect(() => {
    if (isLoggedOut) {
      openNotification('top')
    }
  }, [])

  return (
    <>
      {contextHolder}

      <div className={classes.container}>
        <div className={classes.login}>
          <div className={classes.title}>
            <Row>
              <Col xs={24} sm={11} md={11} xl={11}>
                <img src={logo} className={classes.logo} alt="logo" />
              </Col>
              <Col xs={24} sm={13} md={13} xl={13}>
                <h3>Πρόγραμμα Ψηφιακού Μετασχηματισμού Επιχειρήσεων</h3>
              </Col>
            </Row>
          </div>
          <div className={classes.separator}></div>
          <div className={classes.formOuterContainer}>
            <Form
              form={form}
              onFinish={handleNavigation}
              onFinishFailed={onFinishFailed} // Add the onFinishFailed handler
              autoComplete="on"
            >
              <Row className={classes.afmContainer}>
                <Col xl={3} style={{ maxWidth: '100%', flex: '1 1 100%' }}>
                  <p className={classes.afmLabel}>*Νέος Κωδικός:</p>
                </Col>
              </Row>
              <Row>
                <div className={classes.afmLowerContainer}>
                  <Form.Item
                    name="pass"
                    rules={[
                      {
                        required: true,
                        message: 'Υποχρεωτικό πεδίο!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password className={classes.afmInput} />
                  </Form.Item>
                </div>
              </Row>

              <Row className={classes.afmContainer}>
                <Col xl={3} style={{ maxWidth: '100%', flex: '1 1 100%' }}>
                  <p className={classes.afmLabel}>*Επιβεβαίωση Κωδικού:</p>
                </Col>
              </Row>
              <Row>
                <div className={classes.afmLowerContainer}>
                  <Form.Item
                    name="confirm_pass"
                    dependencies={['pass']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Παρακαλούμε επιβεβαιώστε τον κωδικό πρόσβασής!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('pass') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Οι δύο κωδικοί πρόσβασης δεν ταιριάζουν!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password className={classes.afmInput} onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}/>
                  </Form.Item>





                  {/* <Row className={classes.afmContainer}>
                    <Col xl={3} style={{ maxWidth: '100%', flex: '1 1 100%' }}>
                      <p className={classes.afmLabel}>*Νέος Κωδικός:</p>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      name="pass"
                      rules={[
                        {
                          required: true,
                          message: 'Υποχρεωτικό πεδίο!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password className={classes.inputForm} />
                    </Form.Item>
                  </Row>
                  <br />
                  <Row className={classes.afmContainer}>
                    <Col xl={3} style={{ maxWidth: '100%', flex: '1 1 100%' }}>
                      <p className={classes.afmLabel}>*Επιβεβαίωση Κωδικού:</p>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      name="confirm_pass"
                      dependencies={['pass']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Παρακαλούμε επιβεβαιώστε τον κωδικό πρόσβασής!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('pass') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Οι δύο κωδικοί πρόσβασης δεν ταιριάζουν!'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password className={classes.afmInput} />
                    </Form.Item>
                  </Row> */}







                </div>
              </Row>
              <Row className={classes.loginBtnContainer}>
                <Form.Item>
                  <button
                    type="submit"
                    className={classes.loginBtn}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    Αλλαγή συνθηματικού
                  </button>
                </Form.Item>
              </Row>
              <p className={classes.error}>
                {submitted && window.localStorage.getItem('error') ? `Ο χρήστης δεν βρέθηκε!` : ``}
              </p>
            </Form>
          </div>
        </div>
        <img className={classes.etpa} src={etpa} alt="espa" />
      </div>
    </>
  );
};

export default ForgotPassword;