// context/AuthContext.tsx

import React, { createContext, useState } from 'react';

type AuthContextType = {
  isLoggedOut: boolean;
  setIsLoggedOut: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void; // Add the logout function
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // Function to set isLoggedOut to true when the user logs out successfully
  const logout = () => {
    setIsLoggedOut(true);
  };

  return (
    <AuthContext.Provider value={{ isLoggedOut, setIsLoggedOut, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
