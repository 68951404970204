import React, { useContext, useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Row, Col, Input, Form } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./LoginRequestPassword.module.css";
import LoginForm from "../../routes/Login/LoginForm";
import ggps from "../../img/ggps.png";
import logo from "../../img/logo.png";
import etpa from '../../img/ETPA.png'
import { AuthContext } from '../../context/AuthContext';
import { Button, Divider, notification, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import axios from "axios";

const LoginRequestPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const { isLoggedOut } = useContext(AuthContext);
  const [error, setError] = useState<string>();

  const handleNavigation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/Auth/ForgotPassword`,
        {
          email: email, // Add the email to the data object
        }
      );

      // If the request is successful, show the success notification
      openNotification('top');
    } catch (error: any) {
      console.error('Error fetching ForgotPassword:', error);
      if (error.response && error.response.status === 404) {
        setError("Το Email δεν βρέθηκε, παρακαλούμε ξαναπροσπαθήστε!");
      } else {
        setError(undefined); // Clear the error if there's no 404 error
        // Handle other errors if needed
      }
    }
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.success({
      message: <span className={classes.customNotification}>Έχει σταλεί ένας σύνδεσμος για επαναφορά κωδικού στο email σας!</span>,
      placement,
      className: `${classes.customNotification}`,
      duration: 0
    });
  };

  useEffect(() => {
    if (isLoggedOut) {
      openNotification('top')
    }
  }, [])

  return (
    <>
      {contextHolder}

      <div className={classes.container}>
        {/* <Col lg={12} md={12} sm={24} xs={24}> */}
        <div className={classes.login}>

          <div className={classes.title}>
            <Row>
              <Col xs={24} sm={11} md={11} xl={11}>
                <img src={logo} className={classes.logo} alt="logo" />
              </Col>
              <Col xs={24} sm={13} md={13} xl={13}>
                <h3>Πρόγραμμα Ψηφιακού Μετασχηματισμού Επιχειρήσεων</h3>
              </Col>
            </Row>
          </div>
          <div className={classes.separator}></div>
          {/* <img src={ggps} /> */}
          <div className={classes.formOuterContainer}>

            <Form
              form={form}
              initialValues={{ email }}
              onFinish={handleNavigation}
              autoComplete="on"
            >
              <Row className={classes.afmContainer}>
                <Col xl={3}>
                  <p className={classes.afmLabel}>*Email:</p>
                </Col>
              </Row>
              <Row>
                <div className={classes.afmLowerContainer}>
                  <Form.Item
                    name="email"
                    help={error} // Set the help prop with the error message
                    hasFeedback={!!error} // Show error icon when there's an error
                    validateStatus={error ? "error" : undefined} // Set the validateStatus to "error" when there's an error
                    rules={[
                      { required: true, message: 'Το πεδίο Email είναι υποχρεωτικό' },
                      { type: 'email', message: 'Παρακαλώ εισάγετε ένα έγκυρο Email' },
                    ]}
                  >
                    <Input
                      className={classes.afmInput}
                      onChange={(e) => {
                        setError('');
                        setEmail(e.target.value)
                      }}
                    />
                  </Form.Item>

                </div>
              </Row>
              <Row className={classes.loginBtnContainer}>
                <button
                  type="submit"
                  className={classes.loginBtn}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  Αποστολή νέου συνθηματικού
                </button>
              </Row>
              <p className={classes.error}>{window.localStorage.getItem('error') ? `Το επιλεγμένο ΑΦΜ δεν βρέθηκε!` : ``}</p>
              {console.log(window.localStorage.getItem('afm'))!}
            </Form>
          </div>
        </div>
        <img className={classes.etpa} src={etpa} alt="espa" />
      </div>
    </>
  );
};

export default LoginRequestPassword;
