import { Component } from "react";
import React, { useState, useEffect } from "react"
import { RightOutlined, LoadingOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from "./Login.module.css"
import { Button, Col, Row, Collapse, Select, Checkbox, Form, Input, Spin } from 'antd';
import { useTranslation } from "react-i18next"
import AuthService from "../../services/auth.service";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
type Props = {};

type State = {
    redirect: string | null,
    username: string,
    password: string,
    loading: boolean,
    message: string
};



export default class Login extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);

        this.state = {
            redirect: null,
            username: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        //  alert(currentUser);
        // AuthService.getUserProfile();
        // AuthService.getActions()
        //  new Promise(resolve => setTimeout(resolve, 1800));
        // if (currentUser) {

        //     this.setState({ redirect: window.localStorage.getItem("location") });
        //     <Navigate to={`${window.localStorage.getItem("location")}`} />
        // };





        AuthService.login(window.location.href)




        // <Navigate to={'https://test.gsis.gr/oauth2server/oauth/authorize/>
    }

    componentWillUnmount() {
        // window.location.reload();
    }

    // validationSchema() {
    //     return Yup.object().shape({
    //         username: Yup.string().required("This field is required!"),
    //         password: Yup.string().required("This field is required!"),
    //     });
    // }

    handleLogin(formValue: { username: string; password: string }) {
        const { username, password } = formValue;

        this.setState({
            message: "",
            loading: true
        });



        AuthService.login(window.location.href)






        .then(
            () => {
                this.setState({
                    redirect: "/profile"
                });
            },
            (error: any)=> {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage
                });
            }
        );
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }







        const { loading, message } = this.state;

        const initialValues = {
            username: "",
            password: "",
        };

        const onFinish = (values: any) => {
            AuthService.login(window.location.href)
        };
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };

        return (
            <>
                <div className={classes.container}>
                    <div className={classes.loadContainer}>
                        <div className={classes.loadWrap}>
                            <span className={classes.loadSpan}>Παρακαλώ περιμένετε...</span>
                            <Spin className={classes.spin} indicator={antIcon} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}