import React from "react";
import { Input, Row, Col, Button, Form, Upload, notification } from 'antd';
import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./AddAnnouncement.module.css"
import { UploadOutlined } from '@ant-design/icons'
import { useState } from "react";
import userImg from '../../img/user.png'
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const AddAnnouncement = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [file3, setFile3] = useState<File | null>(null);
  const [file4, setFile4] = useState<File | null>(null);
  const [file5, setFile5] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [formValues, setFormValues] = useState({
    title: "",
    content: "",
  });
  const handleImageChange = (fileList: any) => {
    if (fileList.length > 0) {
      const selectedImage = fileList[0].originFileObj as File;
      setSelectedImage(selectedImage);
    } else {
      setSelectedImage(null);
    }
  };
  const handleFileChange = (info: any, setter: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (info.fileList.length > 0) {
      const nativeFile = info.fileList[info.fileList.length - 1].originFileObj as File;
      setter(nativeFile);
    } else {
      setter(null);
    }
  };

  const onFinish = async (values: any) => {
    console.log(values);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", values.content);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    if (file1) {
      formData.append("file1", file1);
    }
    if (file2) {
      formData.append("file2", file2);
    }
    if (file3) {
      formData.append("file3", file3);
    }
    if (file4) {
      formData.append("file4", file4);
    }
    if (file5) {
      formData.append("file5", file5);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_NAME}/api/News/Create`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);

      notification.success({
        message: 'Η ανακοίνωση δημιουργήθηκε επιτυχώς!',
        placement: 'top' // You can adjust the placement as needed
      });

      form.resetFields();
    } catch (error) {
      console.error(error);
      // Handle error
      notification.error({
        message: 'Δυστυχώς, υπήρξε πρόβλημα κατά την δημιουργία της ανακοίνωσης!',
        placement: 'top', // You can adjust the placement as needed
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
      <div className={classes.container}>
        <Row>




          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            <Row>
              <Col xs={24} sm={20} md={14} lg={14} xl={11}>
                <h2 className={classes.heading}>Δημιουργία Ανακοίνωσης</h2>
              </Col>
              <Col xs={24} sm={4} md={10} lg={10} xl={13}>
                {/* {!editMode ? (
                  <img className={classes.editIcon} src={editIcon} alt="edit" onClick={toggleEditMode} />
                ) : (
                  <img className={classes.editIcon} src={saveIcon} alt="save" onClick={() => form.submit()} />
                )} */}
              </Col>
            </Row>
            <div className={classes.fcontainer}>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={formValues}
              >

                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Τίτλος</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="title">
                      <Input className={classes.mIdInput} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Περιεχόμενο</span>
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="content">
                    <ReactQuill className={classes.mIdInput} style={{height: 'auto', padding: 0}}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={10} xl={8}>
                    {selectedImage ? (
                      <img className={classes.cimg} src={URL.createObjectURL(selectedImage)} alt="" />
                    ) : (
                      <img className={classes.cimgNonSelected} src={userImg} alt="" />
                    )}
                    <br />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={16} className={classes.uploadImgCol}>
                    <Form.Item label="" name="image">
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleImageChange(info.fileList)}
                      >
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα εικόνας
                        </Button>
                        <br />
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Συν. Αρχείο 1</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="file1" className={classes.fileList}>
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleFileChange(info, setFile1)}
                      >
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα αρχείου 1
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Συν. Αρχείο 2</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="file2" className={classes.fileList}>
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleFileChange(info, setFile2)}
                      >
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα αρχείου 2
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Συν. Αρχείο 3</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="file3" className={classes.fileList}>
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleFileChange(info, setFile3)}
                      >
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα αρχείου 3
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Συν. Αρχείο 4</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="file4" className={classes.fileList}>
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleFileChange(info, setFile4)}
                      >
                        <Button type="primary" className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα αρχείου 4
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={7} md={6} lg={10} xl={8}>
                    <span className={classes.clabel}>Συν. Αρχείο 5</span>
                    <br />
                  </Col>
                  <Col xs={24} sm={17} md={18} lg={14} xl={16}>
                    <Form.Item label="" name="file5" className={classes.fileList}>
                      <Upload
                        listType="text"
                        beforeUpload={(file: File) => false}
                        onChange={(info) => handleFileChange(info, setFile5)}
                      >
                        <Button className={classes.delete} style={{ backgroundColor: '#7BB6E1' }} >
                          <UploadOutlined />
                          Ανέβασμα αρχείου 5
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <Form.Item label="" name="submit">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={classes.delete}
                    style={{ backgroundColor: '#263489' }}
                  >
                    Ολοκλήρωση
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>

      </div >
    </>
  )
}

export default AddAnnouncement
