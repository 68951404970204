// RecentNews.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./RecentNews.module.css"
import { Link } from "react-router-dom";

interface NewsItem {
    recid: number;
    title: string;
}

const RecentNews: React.FC<{ newsItems: NewsItem[] }> = ({ newsItems }) => {
    const { t } = useTranslation();

    return (
        <div>
            {newsItems.map((newsItem) => (
                <div className={classes.innerSpan}>
                    <Link to={`/announcements/${newsItem.recid}`}>
                        <div className={classes.footerAtag} ><span><u>{newsItem.title}</u></span></div>
                    </Link>
                </div>

                // <div key={newsItem.id} className={classes.innerSpan}>
                //     <div className={classes.footerAtag}>
                //         <span>
                //             <u>{newsItem.title}</u>
                //         </span>
                //     </div>
                // </div>
            ))}
        </div>
    );
};

export default RecentNews;
