import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form, Input, InputNumber } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Footer.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import twitter from '../../img/twitter.png'
import facebook from '../../img/facebook.png'
import espa from '../../img/ETPA.png'
import axios from "axios";
import RecentNews from "./RecentNews";

const Footer = () => {
    const { t } = useTranslation()

    const [news, setNews] = useState<any[]>([]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: 'Email field is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    const onFinish = (values: any) => {
        console.log(values);
    };

    const sortedNews = [...news].sort(
        (a, b) => new Date(b.date_submitted).getTime() - new Date(a.date_submitted).getTime()
    );

    const lastThreeNews = sortedNews.slice(0, 4);

    useEffect(() => {
        // Fetch all news items
        axios
            .get(`${process.env.REACT_APP_SERVER_NAME}/api/News/GetAll`)
            .then((response) => {
                setNews(response.data);
            })
            .catch((error) => {
                console.error("Error fetching news:", error);
            });
    }, []);

    return (
        <>
            <div className={classes.footerContainer}>
                <Row justify="center">
                    <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 22 }} lg={{ span: 24 }}>
                        <Row justify="center">
                            <Col xs={24} sm={24} md={12} lg={7} className={classes.links} >
                                <div className={classes.innerLeftCol}>
                                    <div className={classes.TitleFooter}>{t('ΕΠΙΚΟΙΝΩΝΙΑ')}</div>
                                    <div className={classes.innerSpan}><span>{t('Εταιρεία Ανάπτυξης και Τουριστικής Προβολής Αθηνών')}</span></div>
                                    <div className={classes.innerSpan}><span>{t('Ξενοφώντος 7, 105 57 Αθήνα, Ελλάδα')}</span></div>
                                    <div className={classes.innerSpan}>
                                        <div className={classes.footerAtag}>
                                            <span>
                                                <a href="tel:+302103253123">+30 210 32 53 123</a>,<br /><a href="+302105201611">+30 210 52 01 611</a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={classes.footerAtag} ><span><a href="mailto:info@developathens.gr">info@developathens.gr</a></span></div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7} className={classes.links}>
                                <div className={classes.innerMiddleCol}>

                                    <div className={classes.TitleFooter}>{t('ΠΡΟΣΦΑΤΕΣ ΑΝΑΚΟΙΝΩΣΕΙΣ')}</div>
                                    <RecentNews newsItems={lastThreeNews} /> {/* Use the RecentNews component */}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={7} className={classes.links}>
                                <div className={classes.innerRightCol}>
                                    <div className={classes.TitleFooter}>{t('ΠΛΗΡΟΦΟΡΙΕΣ')}</div>
                                    {/* <div>
                                        <Form
                                            {...layout}
                                            name="nest-messages"
                                            onFinish={onFinish}
                                            style={{ maxWidth: 600 }}
                                            validateMessages={validateMessages}
                                        >
                                            <Form.Item className={classes.formItem} name={['user', 'email']} label="" rules={[{ type: 'email', required: true }]}>
                                                <Row>
                                                    <Col xs={16}sm={16} md={16} lg={16} xl={16}>
                                                        <Input className={classes.placeholder} style={{ color: '#26348999' }} placeholder="Your email address" />
                                                    </Col>
                                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                        <Button className={classes.signUp} type="primary" htmlType="submit">
                                                            SIGN UP
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </div> */}
                                    <div className={classes.innerSpan}><span><u>{t('Όροι Χρήσης')}</u></span></div>
                                    <div className={classes.innerSpan}><span><u>{t('Προσωπικά δεδομένα στον Διαδικτυακό τόπο')}</u></span></div>
                                    <div className={classes.innerSpan}><span><u>{t('Επικοινωνία με HelpDesk')}</u></span></div>
                                </div>
                            </Col>

                        </Row>
                        <div className={classes.copyrightsContainer} style={{ color: '#fff' }}>
                            © 2023 OTE / <a className={classes.dotsoft} href="https://dotsoft.gr/" target="_blank">DOTSOFT</a>. All Rights Reserved.
                        </div>
                    </Col>
                </Row >

            </div >
        </>
    );
};

export default Footer